@import '../abstracts/variables';

.content-and-featured-products-wrapper {
  display: flex;
  flex-direction: column;
  margin: @spacer * 10 0;

  h2 {
    margin-bottom: @spacer * 1.5;
  }

  .description {
    margin-bottom: @spacer * 2;
  }
}
